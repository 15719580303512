<template>
  <div>
     
        <div class="dropdown pull-right"
            style="float: left"
            :style="($i18n.locale == 'ar') ? 'float:left': 'float:right'">

            <button 
                id="dropdownMenuButton"
                type="button" 
                class="btn btn-light dropdown-toggle" 
                data-toggle="dropdown" 
                aria-haspopup="true" 
                aria-expanded="false"
                :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                :style="($i18n.locale == 'ar') ? 'direction: ltr' : 'direction: rtl'">
                <span>{{ $t('app.show') }} {{ perPage }}</span>
                <i class="mdi mdi-chevron-down"></i>
            </button>

            <div 
                class="dropdown-menu ui-min-w100" 
                aria-labelledby="dropdownMenuButton"
                :style="($i18n.locale == 'ar') ? 'margin-right: -80px' : ''">
                
                <a  v-for="(entry, index) in entries"
                    :key="index"
                    class="dropdown-item cursor-pointer"
                    :class="(current == entry) ? 'active' : ''"
                    @click="handleChange(entry)">
                    {{ entry }}
                </a>

            </div>

        </div>

  </div>
</template>

<script>
export default {
    name: 'ShowEntries',
    props: ['perPage'],
    data(){
        return {
            //
            current: this.$props.perPage,
            entries: [10, 25, 50, 100]
        }
    },
    mounted() {},
    created() {},
    methods: {
        //
        handleChange(event) {
            this.current = event;
            this.$emit('showChange', event)
        }
    }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
